import React from "react"

import BaseChild from "./base-child"
import useAboutHeroQuery from "../../../hooks/use-about-hero-query"

const BaseAbout = ({ title, html, htmlClass, children }) => {
  const gatsbyImageData = useAboutHeroQuery()
  return (
    <BaseChild
      title={title}
      hero={gatsbyImageData}
      html={html}
      htmlClass={htmlClass}
    >
      {children}
    </BaseChild>
  )
}

export default BaseAbout
