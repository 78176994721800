import React from "react"
import { graphql } from "gatsby"
import BaseAbout from "../../components/layout/page/base-about"

const WhatWeDoAbout = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title },
    },
  } = data

  return <BaseAbout title={title} html={html} />
}

export default WhatWeDoAbout

export const query = graphql`
  query WhatWeDoQuery {
    markdownRemark(frontmatter: { name: { eq: "what-we-do" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
